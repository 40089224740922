import { memo } from "react";
import { AiFillInfoCircle } from "react-icons/ai";

const TeleopAck = ({
  robotDetails,
  data,
  handlerobotdata,
  getRobotDot,
  getRobotStatus,
  getRunningStatusColor,
}) => {
  return (
    <div
      className="border-red rounded-[8px] flex w-full lg:w-[32%] bg-[#1c1e2a]"
      onClick={() => handlerobotdata(robotDetails[data])}
    >
      <div className="relative fleet__card_details">
        <button className="Error-state-teleop-ack-button"> Teleop Ack</button>
        <div className="flex justify-between w-full d-flex-center">
          <div className="fleet__card__robot_details_container">
            <div className="fleet__card__robot_details_robot_status_container group">
              <div className="flex items-center gap-2.5">
                {parseInt(robotDetails[data]?.robotBattery).toFixed(0) <=
                  20 && (
                  <span className="relative p-1.5 bg-[#fb6262] rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35.006"
                      height="34.986"
                      viewBox="0 0 35.006 34.986"
                    >
                      <g
                        id="Group_152"
                        data-name="Group 152"
                        transform="translate(-733.997 -574.007)"
                      >
                        <g
                          id="noun-battery-149442"
                          transform="translate(723.279 563.493)"
                        >
                          <path
                            id="Path_107"
                            data-name="Path 107"
                            d="M16.654,16.439a16.35,16.35,0,0,1,22.358-.722H36.527V16.6h4.05v-4.05h-.884v2.583a17.243,17.243,0,0,0-27,20.359l.8-.384A16.376,16.376,0,0,1,16.654,16.439Zm27.123,4.123-.8.382A16.357,16.357,0,0,1,17.468,40.33h2.481v-.884H15.9V43.5h.884V40.907A17.243,17.243,0,0,0,43.777,20.562Z"
                            transform="translate(0)"
                            fill="#fff"
                            stroke="#fff"
                            strokeWidth="0.5"
                          />
                        </g>
                        <g
                          id="Rectangle_164"
                          data-name="Rectangle 164"
                          transform="translate(739 585)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1"
                        >
                          <rect width="23" height="13" rx="4" stroke="none" />
                          <rect
                            x="0.5"
                            y="0.5"
                            width="22"
                            height="12"
                            rx="3.5"
                            fill="none"
                          />
                        </g>
                        <rect
                          id="Rectangle_165"
                          data-name="Rectangle 165"
                          width="3"
                          height="4"
                          rx="1.5"
                          transform="translate(761 590)"
                          fill="#fff"
                        />
                        <rect
                          id="Rectangle_166"
                          data-name="Rectangle 166"
                          width="6"
                          height="7"
                          transform="translate(742 588)"
                          fill="#fff"
                        />
                      </g>
                    </svg>

                    <span className="group-hover:flex absolute -top-1 left-8 z-[1] hidden justify-center pb-0.5 pl-0.5 pr-1 w-max gap-0.5 items-center rounded-full bg-[#fb6262]">
                      <AiFillInfoCircle className="text-white h-[13px] w-[13px]" />
                      {parseInt(robotDetails[data]?.robotBattery).toFixed(0) <
                      0 ? (
                        <p className="text-[10px] text-white font-lilita italic font-light">
                          Battery Error
                        </p>
                      ) : (
                        <p className="text-[10px] text-white font-lilita italic font-light">
                          Battery Swap Required
                        </p>
                      )}
                    </span>
                  </span>
                )}
                <div className="fleet__card__robot_details_robot_status">
                  <span className="fleet__card__robot_details_robot_id">
                    {" "}
                    {robotDetails[data]?.robotId}
                  </span>
                  {getRobotDot(robotDetails[data]?.robotStatus)}
                  {getRobotStatus(robotDetails[data])}
                </div>
              </div>
              <div className="fleet__card__robot_details_robot_battery_container">
                <div className="fleet__card__robot_details_robot_battery_details">
                  <div className="battery_icon">
                    <span className="battery__percentage">
                      <strong
                        style={{
                          background:
                            parseInt(robotDetails[data]?.robotBattery).toFixed(
                              0
                            ) <= 20
                              ? "#E4261C"
                              : "rgb(113, 207, 98)",
                          width: `${parseInt(
                            robotDetails[data]?.robotBattery
                          ).toFixed(0)}%`,
                          display: "block",
                          height: "8px",
                        }}
                      ></strong>
                    </span>
                  </div>
                  <p>
                    {parseInt(robotDetails[data]?.robotBattery).toFixed(0)}%
                  </p>
                </div>
                <div
                  className={getRunningStatusColor(
                    robotDetails[data].robotRunningState
                  )}
                >
                  <span className="state_border">
                    {robotDetails[data].robotRunningState}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TeleopAck);
