import { useState } from "react";
import { useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  DirectionsRenderer,
} from "react-google-maps";
import { Polygon } from "react-google-maps";
import { robotIcon } from "./robotIcon";
import MedianIncomeKml from "./testmap.kml";
import { KmlLayer } from "react-google-maps";
import ReactGoogleMapLoader from "react-google-maps-loader";
// import { DistanceMatrixService } from "@react-google-maps/api";
import * as React from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRobotDirection } from "../redux/actions";
import { robotConstants } from "../redux/constants";
// let directions = {};
let directions = JSON.parse(localStorage.getItem("directionRoutes"))
  ? JSON.parse(localStorage.getItem("directionRoutes"))
  : {};
var image = {};
let origin;
let destination;
let fivesec = true;
let kmlurl = localStorage.getItem("kmlurl");

const options = {
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "#003300",
  strokeOpacity: 1,
  strokeWeight: 4,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
const optionsteleop = {
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "rgb(220,53,69)",
  strokeOpacity: 1,
  strokeWeight: 4,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
const optionsteleoptwo = {
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "#7859bc",
  strokeOpacity: 1,
  strokeWeight: 4,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const robotpathoptions = {
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "rgb(113, 207, 98)",
  strokeOpacity: 1,
  strokeWeight: 8,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const onLoad = (polygon) => {
  // console.log("polygon: ", polygon);
};

const GoogleMapCon = ({ props, item, robotDetails }) => {
  const robotId = props.robotId;
  const dispatch = useDispatch();

  const directionsService = new window.google.maps.DirectionsService();

  image = {
    url: "/assets/images/robot-icon-pickup.svg",
    // url: '/assets/images/robot-icon-dropoff.svg',
    // size:new window.google.maps.Size(50, 50),
    scaledSize: new window.google.maps.Size(25, 25),
  };
  if (robotDetails[robotId]?.robotCommandList?.length > 0) {
    // console.log("Inside Direction Service3");
    origin = {
      lat: parseFloat(item.latitude),
      lng: parseFloat(item.longitude),
    };
    destination = {
      lat: parseFloat(props.robotlatitude),
      lng: parseFloat(props.robotlongitude),
    };
    // dispatch(getRobotDirection(directionsService, origin, destination));
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.WALKING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          // directions = result;
          localStorage.setItem("directionRoutes", JSON.stringify(result));
          dispatch({
            type: robotConstants.ROBOT_DIRECTIONS_SUCCESS,
            payload: result,
          });
          // console.log(directions);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  return <></>;
};

// const googlemapcon = (props, item) => {
//   console.log("Inside Direction Service");
// };

const checkActionId = (commandListData, props) => {
  // console.log(direction);
  // console.log(commandListData);
  if (
    !localStorage.getItem("actionId") ||
    localStorage.getItem("actionId") === "null"
  ) {
    // console.log("FIRST SAVE");
    localStorage.setItem(
      "actionId",
      JSON.stringify({
        actionType: commandListData.actionType,
        actionId: commandListData.actionId,
      })
    );
    // console.log("First ", localStorage.getItem("actionId"));
    return true;
  } else {
    if (
      commandListData.actionId !==
      JSON.parse(localStorage.getItem("actionId")).actionId
    ) {
      // console.log(direction);
      // console.log(commandListData.actionId);
      // console.log("ACTION ID NOT EQUAL");
      localStorage.setItem(
        "actionId",
        JSON.stringify({
          actionType: commandListData.actionType,
          actionId: commandListData.actionId,
        })
      );
      // console.log("Second ", localStorage.getItem("actionId"));
      return true;
    } else {
      // props.setToggleDirection(false);
      // console.log(setToggleDirection(false));
      // console.log("ACTION ID SAME");
      return false;
    }
  }
};

const GoogleMapMain = withScriptjs(
  withGoogleMap((props) => {
    const robotId = props.robotId;
    const robotDetails = useSelector((state) => state.robotDetailsReducer);
    // const toggleDirections, setToggleDirections = useState(props.toggleDirections)
    const { robotDirections } = useSelector(
      (state) => state.robotDirectionsReducer
    );
    return (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{
          lat: parseFloat(robotDetails[robotId]?.latitude),
          lng: parseFloat(robotDetails[robotId]?.longitude),
        }}
        yesIWantToUseGoogleMapApiInternals
      >
        <KmlLayer url={`${kmlurl}`}></KmlLayer>
        {/* {console.log('kmlurlkmlurlkmlurlkmlurl',kmlurl)} */}
        {/* {robotDetails[robotId]?.robotCommandList?.length <=0 ?  */}
        <Marker
          position={{
            lat: parseFloat(robotDetails[robotId]?.latitude),
            lng: parseFloat(robotDetails[robotId]?.longitude),
          }}
          icon={{
            url: "/assets/images/robot-icon-pickup_1.svg",
            scaledSize: new window.google.maps.Size(30, 30),
          }}
        ></Marker>
        {/* :null } */}

        <Polygon onLoad={onLoad} paths={props.nogozones} options={options} />
        <Polygon
          onLoad={onLoad}
          paths={props.geofencezone}
          options={optionsteleop}
        />
        {/* {console.log('props.fleetzones',props.fleetzones)}
                      {console.log('props.geofencezone',props.geofencezone)} */}
        {props?.fleetzones?.map((item, index) => {
          return (
            <Polygon
              key={"polygon__" + index}
              onLoad={onLoad}
              paths={item}
              options={optionsteleoptwo}
            />
          );
        })}

        {props?.robotpathcheck ||
        robotDetails[robotId]?.robotCommandList?.map((item, index) =>
          item.actionType.includes("MOVE")
        ) ? null : (
          <Polyline
            geodesic={true}
            onLoad={onLoad}
            path={robotDetails[robotId]?.path}
            options={robotpathoptions}
          />
        )}
        {/* {console.log('robotDetails[robotId]?.robotCommandList', props.commandlistdata)} */}
        {robotDetails[robotId]?.robotCommandList?.length > 0 &&
          robotDetails[robotId]?.robotCommandList.map(
            (item, index) =>
              item.actionPriority === 1 && (
                <div key={"Marker_key_" + index}>
                  {/* <Marker position={destination} icon={{
                          url: '/assets/images/robot-icon-pickup_1.svg',
                          scaledSize: new window.google.maps.Size(30, 30),
                          }}     /> */}

                  <Marker
                    key={"marker__no_" + index}
                    position={{
                      lat: parseFloat(item.latitude),
                      lng: parseFloat(item.longitude),
                    }}
                    icon={{
                      url: "/assets/images/robot-icon-dropoff_1.svg",
                      scaledSize: new window.google.maps.Size(30, 30),
                    }}
                  />

                  {/* {console.log('directions',directions,'destination',destination,'origin',origin)} */}
                  {props.robotpathcheck && item.actionType.includes("MOVE") ? (
                    <>
                      {checkActionId(item, props) && (
                        <GoogleMapCon
                          key={"google__map_" + index}
                          props={props}
                          item={item}
                          robotDetails={robotDetails}
                        />
                      )}
                      {/* {googlemapcon()} */}
                      <DirectionsRenderer
                        key={"google__map_directions_" + index}
                        // directions={JSON.parse(
                        //   localStorage.getItem("directionRoutes")
                        // )}
                        directions={directions}
                        options={{
                          suppressMarkers: true,
                          // markerOptions: { icon: image },
                          polylineOptions: {
                            stokeColor: "#ed716c",
                            strokeOpacity: 0.5,
                            fill: "#ed716c",
                            strokeWeight: 10,
                          },
                        }}
                      />{" "}
                    </>
                  ) : (
                    <Polyline
                      key={"poly__" + index}
                      geodesic={true}
                      onLoad={onLoad}
                      path={robotDetails[robotId]?.path}
                      options={robotpathoptions}
                    />
                  )}
                  {/* <DistanceMatrixService 
                options={{
                  destinations: [{ lat: parseFloat(robotDetails[robotId]?.robotCommandList[0]?.latitude), lng: parseFloat(robotDetails[robotId]?.robotCommandList[0]?.longitude)}],
                  origins: [{ lat: parseFloat(props.robotlatitude), lng: parseFloat(props.robotlongitude) }],
                  travelMode: "WALKING",
                }}
                callback = {(response) => {
                console.log('Distance response',response)
                localStorage.setItem('Distance', response.rows[0].elements[0].distance.text)
                } }
                /> */}
                </div>
              )
          )}
      </GoogleMap>
    );
  })
);

export default React.memo(GoogleMapMain);
