import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import IndividualRobot from "./IndividualRobot";
import TeleopAck from "../subcomponents/TeleopAck";

function SelectFleet() {
  const [fleetlist, setfleetlist] = useState();

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userEmail = localStorage.getItem("useremail");
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const userRole = localStorage.getItem("role");

  const wholeData = useLocation();

  const robotMsgData = useSelector((state) => state.robotMessageReducer);
  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  let teleop = [];
  let robotRunningMessages = [];

  const [showheading, setshowheading] = useState(false);
  const [headingflag, setheadingflag] = useState(false);

  const useremail = localStorage.getItem("useremail");

  const calculateFleetLength = (fleetId) => {
    let count = 0;
    Object.keys(robotDetails).map((id) => {
      if (robotDetails[id].fleetId === fleetId) {
        count++;
      }
      return null;
    });
    return count;
  };

  useEffect(() => {
    if (
      token === "" ||
      token === "null" ||
      token === null ||
      token === undefined ||
      useremail === null ||
      useremail === "null" ||
      useremail === undefined
    ) {
      window.location.replace("/");
    } else {
      setfleetlist(wholeData?.state?.name?.fleet);
    }
    setheadingflag(false);

    Object.keys(robotDetails)?.map((data) => {
      if (
        robotDetails[data].teleop === "YES" &&
        robotDetails[data].robotRunningState !== "TELE_OP_MOVING" &&
        robotDetails[data].robotRunningState !== "TELE_OP_OBSTACLE" &&
        robotDetails[data].robotRunningState !== "TELE_OP"
      ) {
        setheadingflag(true);
      }
      if (setheadingflag) {
        setshowheading(true);
      }
      return null;
    });
  }, [
    wholeData,
    robotMsgData,
    showheading,
    teleop,
    robotRunningMessages,
    robotDetails,
    token,
    useremail,
  ]);

  const getRobotStatus = (robot) => {
    switch (robot?.robotStatus) {
      case "AVAILABLE":
        return (
          <>
            <span className="m-0 text-left uppercase p-color-green">
              Available{" "}
            </span>{" "}
            <span className="text-[#f7ca28] text-xs lowercase">
              {/* ({robot.robotRunningState.split("_").join(" ")}) */}
            </span>
          </>
        );
      case "UNAVAILABLE":
        return (
          <>
            <span className="m-0 text-left uppercase p-color-red">
              {" "}
              Unavailable{" "}
            </span>{" "}
            <span className="text-[#f7ca28] text-xs lowercase">
              {/* ({robot.robotRunningState.split("_").join(" ")}) */}
            </span>
          </>
        );
      case "CHARGING":
        return (
          <>
            <span className="m-0 text-left uppercase p-color-green">
              Charging{" "}
            </span>
            <span className="text-[#f7ca28] text-xs lowercase">
              {/* ({robot.robotRunningState.split("_").join(" ")}) */}
            </span>
          </>
        );
      case "ERROR":
        return <p className="m-0 text-left p-color-red"> Error</p>;
      default:
        return (
          <p className="m-0 text-left p-color-red"> {robot?.robotStatus}</p>
        );
    }
  };

  const getRunningStatusColor = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "ERROR":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "AUTO_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "AUTO_RUN_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "AUTO_RUN_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div AutoRun_moving_p";
      case "AUTO_RUN":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "TELE_OP_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "TELE_OP_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div teleopMoving_bordertop_p";
      case "TELE_OP":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "PAYLOAD_STATE":
        return "Dashboard_page_individual_robot_robotstatus_div payloadState_bordertop_p";
      case "MANUAL":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      default:
        return "Dashboard_page_individual_robot_robotstatus_div defaultBorderTop_P";
    }
  };

  const getRobotDot = (status) => {
    switch (status) {
      case "UNAVAILABLE":
        return <span className="red-dot"> </span>;
      case "AVAILABLE":
        return <span className="green-dot"> </span>;
      case "ERROR":
        return <span className="yellow-dot"> </span>;
      default:
        return <span className="red-dot"> </span>;
    }
  };
  const handlerobotdata = (robotdata) => {
    localStorage.setItem("fleetId", robotdata?.fleetId);
    navigate(`/dashboard/${robotdata?.robotId}`, {
      state: { name: robotdata },
    });
  };

  return (
    <>
      <Header
        useremail={userEmail}
        firstName={firstName}
        lastName={lastName}
        userRole={userRole}
      />
      <div className="flex flex-col flex-wrap gap-4 container-fluid SelectFleetPage-wrapper-Main sm:px-[30px]">
        <div
          className={`${
            headingflag &&
            "flex flex-col bg-[#292F40] p-4 rounded-lg gap-2 w-full py-3"
          }`}
        >
          {headingflag && (
            <>
              <span className="!text-[#00B7D4] !text-left font-extrabold text-lg mb-0">
                Robot Message
              </span>
              <audio autoPlay={true} loop>
                <source
                  src="/static/media/alert.aa9b8554cbeaf7968e81.wav"
                  type="audio/wav"
                ></source>
              </audio>
            </>
          )}

          <div className="flex flex-col gap-3 sm:flex-wrap sm:flex-row">
            {Object.keys(robotDetails).map(
              (data, index) =>
                robotDetails[data].teleop === "YES" &&
                robotDetails[data].robotRunningState !== "TELE_OP_MOVING" &&
                robotDetails[data].robotRunningState !== "TELE_OP_OBSTACLE" &&
                robotDetails[data].robotRunningState !== "TELE_OP" && (
                  <TeleopAck
                    key={"robot__details_" + index}
                    robotDetails={robotDetails}
                    data={data}
                    handlerobotdata={handlerobotdata}
                    getRobotDot={getRobotDot}
                    getRobotStatus={getRobotStatus}
                    getRunningStatusColor={getRunningStatusColor}
                  />
                )
            )}
          </div>
        </div>
        <div className="flex flex-col sm:flex-wrap sm:flex-row SelectFleetPage-content-wrapper-Main ">
          {fleetlist?.map((data, index) => {
            return (
              <div
                key={"robot__detail_" + index}
                className="flex w-full lg:w-[32%] flex-col bg-[#292F40] p-[15px] rounded-[8px]"
              >
                <span className="Dashboard-content-div-one-left-side-h3">
                  {data.fleetName}
                </span>
                <IndividualRobot
                  data={data}
                  latitude={data?.latitude}
                  longitude={data?.longitude}
                  fleetLength={calculateFleetLength(data.fleetId)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default React.memo(SelectFleet);
