import React, { useCallback, useEffect, useRef, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "../App.css";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import Battery80Icon from "@mui/icons-material/Battery80";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { FormControlLabel, Switch } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import {
  Autorunplay,
  changeheadlightStatus,
  changeringlightStatus,
  changeRobotWanderStatus,
  closedoorone,
  closedoortwo,
  DisengageMotors,
  EmergencyStop,
  getfleetzones,
  getgeofenceZone,
  getnogozones,
  getrobotpath,
  getrobotpathMain,
  getrobotStatus,
  handleteleopAck,
  handleteleopAckinterrupt,
  handleteleopComplete,
  opendoorone,
  opendoortwo,
  Openssh,
  pauserobot,
  playrobot,
  ReleaseEmergencyStop,
  resetDiagnostics,
  restartvideo,
  robotactionapi,
  robotListfleetId,
  sendhome,
} from "../API";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DisengageMotorsModal from "../subcomponents/DisengageMotorsModal";
import OpenSSHModal from "../subcomponents/OpenSSHModal";
import AutorunMoving from "../subcomponents/AutorunMoving";
import { toast, ToastContainer } from "react-toastify";
import RobotActions from "../subcomponents/RobotActions";
import TeleopCompleted from "../subcomponents/TeleopCompleted";
import { useDispatch, useSelector } from "react-redux";
import GoogleMapMain from "../subcomponents/GoogleMapMain";
import MapboxMain from "../subcomponents/MapboxMain";
import LogoutModel from "../subcomponents/LogoutModel";
// import Cookies from "universal-cookie";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Maps from "../subcomponents/Maps";
// import io from "socket.io-client";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  destroySession,
  getRobotAction,
  getRobotDetail,
  getRobotPath,
  storeRobotDetails,
} from "../redux/actions";
import { AiFillInfoCircle } from "react-icons/ai";

function Dashboard() {
  const [robotData, setrobotData] = useState();
  const navigate = useNavigate();
  const isLogged = localStorage.getItem("isLoggedin");
  const robotID = useLocation();
  const token = localStorage.getItem("token");
  const [playbtn, setplaybtn] = useState(false);
  const [robotpathmain, setrobotpathmain] = useState();
  let fleetId = localStorage.getItem("fleetId");
  let robotId = robotID?.state?.name?.robotId;
  const [isstop, setisStop] = useState();
  const [geofencezone, setgeofencezone] = useState();
  const [fleetzones, setfleetzones] = useState();
  const [nogozones, setnogozones] = useState();
  const [map2D, setmap2d] = useState(false);
  const DiagnoticsResetfialed = () =>
    toast.error("Reset Diagnostics Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const ResetDiagnosticsSuccess = () =>
    toast.success("Reset Diagnostics Success", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const RestartVideosuccess = () =>
    toast.success("Restart Video Success", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const RestartVideoError = () =>
    toast.error("Restart Video Error", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const sendHomeError = () =>
    toast.error("Send Home Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const sendHomeSuccess = () =>
    toast.success("Send Home Success", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const WanderModeError = () =>
    toast.error("Wander Mode Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const WanderModeSuccess = () =>
    toast.success("Wander Mode Successfully Changed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const ringlightError = () =>
    toast.error("Ring Light Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const ringlightSuccess = () =>
    toast.success("Ring Light Successfully Changed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const headlightError = () =>
    toast.error("Head Light Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const headlightSuccess = () =>
    toast.success("Head Light Successfully Changed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const EmergencyStopError = () =>
    toast.error("Emergency Stop Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const EmergencyStopSuccess = () =>
    toast.success("Emergency Stop Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const ReleaseStopError = () =>
    toast.error("Release Stop Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const ReleaseStopSuccess = () =>
    toast.success("Release Stop Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const OpenDoor1Error = () =>
    toast.error("OpenDoor1 Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const OpenDoor1Success = () =>
    toast.success("OpenDoor1 Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const OpenDoor2Error = () =>
    toast.error("OpenDoor2 Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const OpenDoor2Success = () =>
    toast.success("OpenDoor2 Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const CloseDoor1Error = () =>
    toast.error("CloseDoor1 Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const CloseDoor1Success = () =>
    toast.success("CloseDoor1 Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const CloseDoor2Error = () =>
    toast.error("CloseDoor2 Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const CloseDoor2Success = () =>
    toast.success("CloseDoor2 Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const playError = () =>
    toast.error("Play Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const playSuccess = () =>
    toast.success("Play Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const pauseError = () =>
    toast.error("Pause Failed", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const pauseSuccess = () =>
    toast.success("Pause Successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

  const sshEndPoint = useSelector((state) => state.sshendpointReducer);

  var latitude = 38.89;
  var logitude = -77.03;
  const [commandlistdata, setcommandlistdata] = useState();
  let mapheight = window.screen.height - 200;
  const handlegooglemap = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const dispatch = useDispatch();
  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  useEffect(() => {
    if (
      token === "" ||
      token === "null" ||
      token === null ||
      token === undefined
    ) {
      window.location.replace("/");
    }

    getgeofenceZone(fleetId, token)
      .then((res) => {
        //  console.log('Geofence Response', res)
        if (!res.data.geofence) {
          setgeofencezone([]);
        } else {
          setgeofencezone(res.data.geofence);
        }
      })
      .catch((err) => {
        console.log("GeoFenceError", err);
      });

    getfleetzones(fleetId, token)
      .then((res) => {
        // console.log('FleetZone Response', res)
        if (!res.data.teleoperationZones) {
          setfleetzones([]);
        } else {
          setfleetzones(res.data.teleoperationZones);
        }
      })
      .catch((err) => {
        console.log("FleetZone errr", err);
      });
    getnogozones(fleetId, token)
      .then((res) => {
        // console.log('NogoZone Response', res)
        if (!res.data.nogoZones) {
          setnogozones([]);
        } else {
          setnogozones(res.data.nogoZones);
        }
      })
      .catch((err) => {
        console.log("NogoZoneERr", err);
      });
  }, []);

  const updateState = useCallback(async () => {
    let robotIdfromURL = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    if (robotId === null || robotId === "" || robotId === undefined) {
      dispatch(storeRobotDetails(fleetId));

      // console.log("res", res);

      // console.log("result", res);
      let singlerobotdata = Object.keys(robotDetails).filter(
        (item) => item === robotIdfromURL
      );
      // console.log("singlerobotdata", singlerobotdata);
      robotId = singlerobotdata.robotId;
    }

    if (isLogged === "LoginFalse") {
      window.location.replace("/");
    } else {
      dispatch(getRobotDetail(robotId));
      // const robotId=localStorage.getItem('robotId')
      // console.log('GetRobotStatus response Dashboard', res)
      // let robotStatus = {};
      // robotStatus.robotId = res.data.data.robotStatus;
      localStorage.setItem("kmlurl", robotDetails[robotId]?.kmlURL);
      // dispatch(getRobotAction(robotId));
      // dispatch(getRobotPath(robotId));
    }
  }, [dispatch, fleetId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 2000);

  // console.log(robotDetails[robotId]?.latitude);

  const [state, setState] = React.useState({
    testingmode: false,
    wandermode: false,
    ringlight: false,
    headlight: false,
    robotpathcheck: false,
    googlemap: false,
  });
  const [toggleDirection, setToggleDirection] = useState(true);
  // const handletestingChange = (event) => {
  //     setState({
  //       ...state,
  //       [event.target.name]: event.target.checked,
  //     });

  //       RobotTestingMode(robotId,token).then((res)=>{
  //          console.log('Testing Mode Response',res)
  //         }
  //       ).catch((err)=>{
  //         console.log('Testing Mode Error', err)
  //       })
  //   };
  const handlewanderChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    changeRobotWanderStatus(robotId, token, state.wandermode)
      .then((res) => {
        // console.log('Change Robot Wander Status Response', res)
        WanderModeSuccess();
      })
      .catch((error) => {
        // console.log('Change Robot Wander Status Error', error)
        WanderModeError();
      });
  };
  const handleringlightChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });

    changeringlightStatus(robotId, token, state.ringlight)
      .then((res) => {
        // console.log('Change Robot Wander Status Response', res)
        ringlightSuccess();
      })
      .catch((error) => {
        // console.log('Change Robot Wander Status Error', error)
        ringlightError();
      });
  };
  const handleheadlightchange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    changeheadlightStatus(robotId, token, state.headlight)
      .then((res) => {
        // console.log('Change Robot Wander Status Response', res)
        headlightSuccess();
      })
      .catch((error) => {
        // console.log('Change Robot Wander Status Error', error)
        headlightError();
      });
  };
  const handletrobotpath = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    // setToggleDirection(true);

    if (state.robotpathcheck) {
      localStorage.removeItem("actionId");
      localStorage.removeItem("directionRoutes");
    }
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 36,
    height: 16,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#000",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgb(189,196,224)"
              : "rgb(189,196,224)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 12,
      height: 12,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light" ? "#000" : "rgb(189,196,224)",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      color: "rgb(189,196,224)",
      border: "2px solid rgb(189,196,224)",
    },
  }));
  const handlelogout = () => {
    navigate("/");
    localStorage.setItem("useremail", null);
    localStorage.setItem("password", null);
    localStorage.setItem("token", null);
    localStorage.setItem("fleetID", null);
    localStorage.setItem("robotId", null);
    localStorage.setItem("Isloggedin", "LoginFalse");
  };
  const handlesendHome = (robotId) => {
    sendhome(robotId, token)
      .then((res) => {
        // console.log("SendHomeResponse", res)
        sendHomeSuccess();
      })
      .catch((err) => {
        //  console.log('SendHomeError', err)
        sendHomeError();
      });
  };
  // const handleStartViewer =(robotId)=>{
  //   startViewer(robotId, token)
  //   .then((res)=>{
  //      console.log('Start Viewer Response', res)
  //      setViewerState(true)
  //   })
  //   .catch((err)=>{
  //      console.log('Error In start Viewer', err)
  //      setViewerState(false)

  //     })

  // }
  // const handleStopViewer=()=>{
  //   stopViewer(robotId, token)
  //   .then((res)=>{
  //     console.log('stop Viewer Response', res)
  //     setViewerState(false)
  //  })
  //  .catch((err)=>{
  //     console.log('Error In Stop Viewer', err)
  //     setViewerState(true)
  //    })
  // }
  const handleResetDiagnostics = (robotId) => {
    resetDiagnostics(robotId, token)
      .then((res) => {
        //  console.log('Reset Diagnotics Response', res)
        ResetDiagnosticsSuccess();
      })
      .catch((err) => {
        //  console.log('Reset Diagnotics Error', err)
        DiagnoticsResetfialed();
      });
  };

  const handleOpendoorone = (robotId, cabin) => {
    opendoorone(robotId, token, cabin)
      .then((res) => {
        //  console.log("Open Door 1 Response", res)
        OpenDoor1Success();
      })
      .catch((err) => {
        //  console.log('Open Door 1 Error',err)
        OpenDoor1Error();
      });
  };
  const handleOpendoortwo = (robotId) => {
    opendoortwo(robotId, token)
      .then((res) => {
        // console.log("Open Door 2 Response", res)
        OpenDoor2Success();
      })
      .catch((err) => {
        //  console.log('Open Door 2 Error',err)
        OpenDoor2Error();
      });
  };
  const handleClosedoorone = (robotId, cabin) => {
    closedoorone(robotId, token, cabin)
      .then((res) => {
        //  console.log("Close Door 1 Response", res)
        CloseDoor1Success();
      })
      .catch((err) => {
        //  console.log('Close Door 1 Error',err)
        CloseDoor1Error();
      });
  };
  const handleClosedoortwo = (robotId) => {
    closedoortwo(robotId, token)
      .then((res) => {
        // console.log("Close Door 2 Response", res)
        CloseDoor2Success();
      })
      .catch((err) => {
        //  console.log('Close Door 2 Error',err)
        CloseDoor2Error();
      });
  };
  const handlebackbutton = () => {
    navigate(-1);
  };

  const handleStop = (robotId) => {
    EmergencyStop(robotId, token)
      .then((res) => {
        // console.log('Emergency Stop Response',res)
        EmergencyStopSuccess();
        setisStop(true);
      })
      .catch((err) => {
        // console.log('Emergency Stop Error',err)
        EmergencyStopError();
        setisStop(false);
      });
  };
  const handleRelease = (robotId) => {
    ReleaseEmergencyStop(robotId, token)
      .then((res) => {
        // console.log('Release Emergency Stop Response', res)
        ReleaseStopSuccess();
        setisStop(false);
      })
      .catch((err) => {
        // console.log('Release Emergency Stop Response', err)
        ReleaseStopError();
        setisStop(true);
      });
  };
  const handlePauseIcon = (robotId) => {
    pauserobot(robotId, token)
      .then((res) => {
        // console.log('Pause Button Response', res)
        pauseSuccess();
        setplaybtn(true);
      })
      .catch((err) => {
        // console.log('Pause Button Error' , err)
        pauseError();
      });
  };
  const handlePlayIcon = (robotId) => {
    playrobot(robotId, token)
      .then((res) => {
        //  console.log('Play Button Response', res)
        playSuccess();
        setplaybtn(false);
      })
      .catch((err) => {
        //  console.log('Play Button Error', err)
        playError();
      });
  };

  const getbottombordercolor = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "dashboard-left-side-div-one-wrapper state_Auto_system_fault_error";
      case "ERROR":
        return "dashboard-left-side-div-one-wrapper state_Auto_system_fault_error";
      case "AUTO_OBSTACLE":
        return "dashboard-left-side-div-one-wrapper state_Auto_Obstacle-Teleop_Obstacle";
      case "AUTO_RUN_OBSTACLE":
        return "dashboard-left-side-div-one-wrapper state_Teleop_Autorun";
      case "AUTO_RUN_MOVING":
        return "dashboard-left-side-div-one-wrapper state_Auto_run_moving";
      case "AUTO_RUN":
        return "dashboard-left-side-div-one-wrapper state_Teleop_Autorun";
      case "TELE_OP_OBSTACLE":
        return "dashboard-left-side-div-one-wrapper state_Auto_Obstacle-Teleop_Obstacle";
      case "TELE_OP_MOVING":
        return "dashboard-left-side-div-one-wrapper state_Teleop_Moving";
      case "TELE_OP":
        return "dashboard-left-side-div-one-wrapper state_Teleop_Autorun";
      case "PAYLOAD_STATE":
        return "dashboard-left-side-div-one-wrapper state_payload_state";
      case "MANUAL":
        return "dashboard-left-side-div-one-wrapper state_Teleop_Autorun";
      default:
        return "dashboard-left-side-div-one-wrapper";
    }
  };
  const getbordertop = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoSystem_fault_error_bordertop";
      case "ERROR":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoSystem_fault_error_bordertop";
      case "AUTO_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoObstacle_teleop_obstacle_bordertop";
      case "AUTO_RUN_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "AUTO_RUN_MOVING":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoRun_moving";
      case "AUTO_RUN":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "TELE_OP_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoObstacle_teleop_obstacle_bordertop";
      case "TELE_OP_MOVING":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center teleopMoving_bordertop";
      case "TELE_OP":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "PAYLOAD_STATE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center payloadState_bordertop";
      case "MANUAL":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      default:
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center defaultBorderTop";
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;

  const handlerestartvideo = (robotId) => {
    restartvideo(robotId, token)
      .then((res) => {
        RestartVideosuccess();
        //  console.log('Restart Response',res)
      })
      .catch((err) => {
        RestartVideoError();
        //  console.log('Restart Error', err)
      });
  };
  const handleplusbutton = () => {
    setmap2d(true);
  };
  const handleminusbutton = () => {
    setmap2d(false);
  };
  return (
    <>
      <div className="container-fluid DashBoard-page-Main-Whole-wrapper">
        {/* {console.log('Boolean(robotData?.robotStatus?.wandermode)',Boolean(robotData?.robotStatus?.wanderMode))} */}
        {/* {console.log('robotData1234',robotData)} */}
        {(() => {
          if (token === "" || token === null || token === undefined) {
            // window.location.replace('/')
          }
        })()}
        <div className={getbordertop(robotDetails[robotId]?.robotRunningState)}>
          <div className="flex items-center">
            <span className="cursor-pointer" onClick={handlebackbutton}>
              <ArrowBackIosNewIcon className="text-[#b1b1b1] text-4xl" />
            </span>

            <div className="Dashboard_page_bottom_div_inner_one_div">
              <div className="dashboard_page_Order_Id_Wrapper">
                <div className="dashboard_page_Order_Id_Wrapper_inner_div">
                  <h3>{robotDetails[robotId]?.robotId}</h3>
                </div>
                <div className="text-center Online_offline_div">
                  <span
                    className={
                      robotDetails[robotId]?.connectionStatus === "OFFLINE"
                        ? "p-color-red"
                        : "p-color-green"
                    }
                  >
                    {robotDetails[robotId]?.connectionStatus}
                  </span>
                </div>
              </div>
              <div>
                {robotDetails[robotId]?.connectionStatus === "OFFLINE" ? (
                  <p className="pt-0 pl-0 mb-0 text-xs text-left text-white font-extralight">
                    {" "}
                    {robotDetails[robotId]?.lastUpdatedTime}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="Dashboard_page_bottom_div_inner_two_div">
            <div className="Dashboard_page_bottom_div_inner_two_div_inner_one"></div>
            <div className="Dashboard_page_bottom_div_inner_two_div_inner_two">
              <div className="Estop_reset_diagnostics_wrapper">
                <button
                  onClick={() =>
                    handleResetDiagnostics(robotDetails[robotId]?.robotId)
                  }
                >
                  Reset Diagnostics{" "}
                </button>
                {isstop ? (
                  <button
                    className="Stop-button-dashhboard-page"
                    onClick={() =>
                      handleRelease(robotDetails[robotId]?.robotId)
                    }
                  >
                    {" "}
                    Release{" "}
                  </button>
                ) : (
                  <button
                    className="Stop-button-dashhboard-page"
                    onClick={() => handleStop(robotDetails[robotId]?.robotId)}
                  >
                    {" "}
                    E-STOP{" "}
                  </button>
                )}
              </div>
              <div></div>

              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 9,
                    width: "20ch",
                    background: "black",
                  },
                }}
              >
                {" "}
                <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <DisengageMotorsModal
                    robotId={robotDetails[robotId]?.robotId}
                    robotData={robotDetails[robotId]}
                  />
                </MenuItem>
                <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <button
                    onClick={() =>
                      handlesendHome(robotDetails[robotId]?.robotId)
                    }
                    className="Dashboard_page_sidebar_button"
                  >
                    Send Home{" "}
                  </button>
                </MenuItem>
                <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <OpenSSHModal
                    robotId={robotId}
                    fleetId={fleetId}
                    robotData={robotDetails[robotId]}
                  />
                </MenuItem>
                <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <button
                    className="reset_diagonostics_button"
                    onClick={() =>
                      handleResetDiagnostics(robotDetails[robotId]?.robotId)
                    }
                  >
                    Reset Diagnostics{" "}
                  </button>
                </MenuItem>
                {/* <MenuItem className="Dashboard_page_sidebar_menuItem">
               <button className="Dashboard_page_sidebar_button" onClick={()=>handlerestartvideo(robotData?.robotStatus?.robotId)}> RESTART VIDEO</button>
            </MenuItem>  */}
                <MenuItem className="Dashboard_page_sidebar_menuItem">
                  {isstop ? (
                    <button
                      className="reset_diagonostics_button"
                      onClick={() =>
                        handleRelease(robotDetails[robotId]?.robotId)
                      }
                    >
                      {" "}
                      Release{" "}
                    </button>
                  ) : (
                    <button
                      className="reset_diagonostics_button"
                      onClick={() => handleStop(robotDetails[robotId]?.robotId)}
                    >
                      {" "}
                      E-STOP{" "}
                    </button>
                  )}
                </MenuItem>
                <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <div className="Dashboard_page_top_div_inner_div direction-toggle">
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={state.robotpathcheck}
                          onChange={handletrobotpath}
                          name="robotpathcheck"
                        />
                      }
                      label=""
                      sx={{ zIndex: "999999999" }}
                    />
                    <p className="Dashboard-Page-bottom-div-Main-wrapper-content-p direction_toggle_p">
                      Directions
                    </p>
                  </div>
                </MenuItem>
                <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <div className="Dashboard_page_top_div_inner_div direction-toggle">
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={state.ringlight}
                          onChange={handleringlightChange}
                          name="ringlight"
                        />
                      }
                      label=""
                    />
                    <p className="Dashboard-Page-bottom-div-Main-wrapper-content-p direction_toggle_p">
                      {" "}
                      Ring Light
                    </p>
                  </div>
                </MenuItem>
                <MenuItem className="Dashboard_page_sidebar_menuItem">
                  <div className="Dashboard_page_top_div_inner_div direction-toggle">
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={state.headlight}
                          onChange={handleheadlightchange}
                          name="headlight"
                        />
                      }
                      label=""
                    />
                    <p className="Dashboard-Page-bottom-div-Main-wrapper-content-p direction_toggle_p">
                      {" "}
                      Head Light
                    </p>
                  </div>
                </MenuItem>
                {/* <MenuItem className="Dashboard_page_sidebar_menuItem">
                <div className="Dashboard_page_top_div_inner_div direction-toggle"> 
                  <FormControlLabel
                  control={<IOSSwitch checked={state.googlemap} onChange={handlegooglemap} name="googlemap"/>}
                  label=""
                  />
                  <p className='Dashboard-Page-bottom-div-Main-wrapper-content-p direction_toggle_p'> 2D Map</p>
              </div>
          </MenuItem>   */}
                <MenuItem className="Dashboard_page_sidebar_menuItem_last">
                  <div className="w-full">
                    <LogoutModel dashboard={true} />
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <div className="pl-0 mb-0 row Dashboard_page_whole_wrapper">
          <div className="col-md-3 dashboard-left-side-div-wrapper-Main">
            <div
              className={getbottombordercolor(
                robotDetails[robotId]?.robotRunningState
              )}
            >
              <div className="dashboard-left-side-div-one-inner-div dashboard_page_battery_icon_wrapper">
                <div className="dashboard_left_side_available_div">
                  {robotDetails[robotId]?.robotStatus === "AVAILABLE" ? (
                    <span className="m-0 text-left uppercase p-color-green">
                      Available
                    </span>
                  ) : (
                    <span className="m-0 text-left uppercase p-color-red">
                      {" "}
                      Unavailable
                    </span>
                  )}
                  <p className="text-white mb-0 font-extralight p-0 text-center text-[13px]">
                    {" "}
                    {parseFloat(robotDetails[robotId]?.currentSpeed).toFixed(
                      2
                    )}{" "}
                    m/sec
                  </p>
                </div>
                <div>
                  <div className="dashboard-left-side-div-one-wrapper-battery-div">
                    <div className="battery-icon-css">
                      <span className="w-[30px] border border-solid border-white rounded-[4px] p-[2px] h-[14px] inline-block">
                        <strong
                          style={{
                            background:
                              parseInt(
                                robotDetails[robotId]?.robotBattery
                              ).toFixed(0) <= 20
                                ? "#E4261C"
                                : "rgb(113, 207, 98)",
                            width: `${parseInt(
                              robotDetails[robotId]?.robotBattery
                            ).toFixed(0)}%`,
                            display: "block",
                            height: "8px",
                            borderRadius: "1px",
                          }}
                        ></strong>
                      </span>
                    </div>
                    <p>
                      {parseInt(robotDetails[robotId]?.robotBattery).toFixed(0)}
                      %
                    </p>
                  </div>
                  <span className="bg-white w-[3px] h-[5px] inline-block rounded-[3px] mr-[3px]"></span>
                  <span className="bg-white w-[3px] h-[10px] inline-block rounded-[3px] mr-[3px]"></span>
                  <span className="bg-white w-[3px] h-[15px] inline-block rounded-[3px] mr-[3px]"></span>
                  <span className="bg-white w-[3px] h-[20px] inline-block rounded-[3px] mr-[3px] opacity-50"></span>
                  <span className="bg-white w-[3px] h-[25px] inline-block rounded-[3px] mr-[3px] opacity-50"></span>
                </div>
              </div>
              <AutorunMoving
                robotId={robotId}
                robotData={robotDetails[robotId]}
              />
              <TeleopCompleted
                robotId={robotId}
                robotData={robotDetails[robotId]}
              />
              {parseInt(robotDetails[robotId]?.robotBattery).toFixed(0) <=
                20 && (
                <span className="absolute -top-6 -right-6 p-1.5 bg-[#fb6262] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35.006"
                    height="34.986"
                    viewBox="0 0 35.006 34.986"
                  >
                    <g
                      id="Group_152"
                      data-name="Group 152"
                      transform="translate(-733.997 -574.007)"
                    >
                      <g
                        id="noun-battery-149442"
                        transform="translate(723.279 563.493)"
                      >
                        <path
                          id="Path_107"
                          data-name="Path 107"
                          d="M16.654,16.439a16.35,16.35,0,0,1,22.358-.722H36.527V16.6h4.05v-4.05h-.884v2.583a17.243,17.243,0,0,0-27,20.359l.8-.384A16.376,16.376,0,0,1,16.654,16.439Zm27.123,4.123-.8.382A16.357,16.357,0,0,1,17.468,40.33h2.481v-.884H15.9V43.5h.884V40.907A17.243,17.243,0,0,0,43.777,20.562Z"
                          transform="translate(0)"
                          fill="#fff"
                          stroke="#fff"
                          strokeWidth="0.5"
                        />
                      </g>
                      <g
                        id="Rectangle_164"
                        data-name="Rectangle 164"
                        transform="translate(739 585)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1"
                      >
                        <rect width="23" height="13" rx="4" stroke="none" />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="22"
                          height="12"
                          rx="3.5"
                          fill="none"
                        />
                      </g>
                      <rect
                        id="Rectangle_165"
                        data-name="Rectangle 165"
                        width="3"
                        height="4"
                        rx="1.5"
                        transform="translate(761 590)"
                        fill="#fff"
                      />
                      <rect
                        id="Rectangle_166"
                        data-name="Rectangle 166"
                        width="6"
                        height="7"
                        transform="translate(742 588)"
                        fill="#fff"
                      />
                    </g>
                  </svg>

                  <span className="absolute -top-1 right-8 z-[1] flex justify-center pb-0.5 pl-1 pr-0.5 w-max gap-0.5 items-center rounded-full bg-[#fb6262]">
                    {parseInt(robotDetails[robotId]?.robotBattery).toFixed(0) <
                    0 ? (
                      <p className="text-[10px] text-white font-lilita italic font-light">
                        Battery Error
                      </p>
                    ) : (
                      <p className="text-[10px] text-white font-lilita italic font-light">
                        Battery Swap Required
                      </p>
                    )}
                    <AiFillInfoCircle className="text-white h-[13px] w-[13px]" />
                  </span>
                </span>
              )}
            </div>

            {/* <div className="dashboard-left-side-div-two-wrapper">

            </div> */}

            <div className="dashboard-left-side-div-three-wrapper">
              <h5 className="dashboard-left-side-div-two-wrapper-h5">
                {" "}
                <span>
                  <MeetingRoomOutlinedIcon className="text-[#b1b1b1]" />{" "}
                </span>
                Cabin Controls
              </h5>
              <div className="dashboard-left-side-div-two-robot-button-Container d-flex-center">
                {robotDetails[robotId]?.cabinStatus?.length > 0 ? (
                  <>
                    {robotDetails[robotId]?.robotRunningState ===
                    "PAYLOAD_STATE" ? (
                      <>
                        {robotDetails[robotId]?.cabinStatus.map((item, key) => {
                          if (
                            robotDetails[robotId]?.doorStatus[key] === "OPEN"
                          ) {
                            return (
                              <button
                                className={
                                  item === "0"
                                    ? "Dashboardpage_CloseDoor_button opendoor_btn_green"
                                    : "Dashboardpage_CloseDoor_button"
                                }
                                onClick={() =>
                                  handleClosedoorone(
                                    robotDetails[robotId]?.robotId,
                                    `CABIN${key + 1}`
                                  )
                                }
                              >
                                Close Cabin {key + 1}
                              </button>
                            );
                          } else {
                            return (
                              <button
                                className={
                                  item === "0" ? "opendoor_btn_green" : ""
                                }
                                onClick={() =>
                                  handleOpendoorone(
                                    robotDetails[robotId]?.robotId,
                                    `CABIN${key + 1}`
                                  )
                                }
                              >
                                Open Cabin {key + 1}
                              </button>
                            );
                          }
                        })}
                      </>
                    ) : (
                      <>
                        {robotDetails[robotId]?.cabinStatus.map((item, key) => {
                          return (
                            <button
                              className={
                                item === "0"
                                  ? "opendoor_btn_green Dashboard_page_OpenDoor_deactivate"
                                  : "Dashboard_page_OpenDoor_deactivate"
                              }
                              type="button"
                              disabled
                            >
                              {" "}
                              Open Cabin{key + 1}{" "}
                            </button>
                          );
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {robotDetails[robotId]?.robotRunningState ===
                    "PAYLOAD_STATE" ? (
                      <>
                        {" "}
                        {robotDetails[robotId]?.door1Status === "CLOSE" ? (
                          <button
                            onClick={() =>
                              handleOpendoorone(
                                robotDetails[robotId]?.robotId,
                                "CABIN1"
                              )
                            }
                          >
                            {" "}
                            Open Cabin 1{" "}
                          </button>
                        ) : (
                          <button
                            className="Dashboardpage_CloseDoor_button"
                            onClick={() =>
                              handleClosedoorone(
                                robotDetails[robotId]?.robotId,
                                "CABIN1"
                              )
                            }
                          >
                            {" "}
                            Close Cabin 1{" "}
                          </button>
                        )}
                        {robotDetails[robotId]?.door2Status === "CLOSE" ? (
                          <button
                            onClick={() =>
                              handleOpendoortwo(robotDetails[robotId]?.robotId)
                            }
                          >
                            {" "}
                            Open Cabin 2{" "}
                          </button>
                        ) : (
                          <button
                            className="Dashboardpage_CloseDoor_button"
                            onClick={() =>
                              handleClosedoortwo(robotDetails[robotId]?.robotId)
                            }
                          >
                            {" "}
                            Close Cabin 2{" "}
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        <button
                          className="Dashboard_page_OpenDoor_deactivate"
                          type="button"
                          disabled
                        >
                          {" "}
                          Open Cabin 1{" "}
                        </button>
                        <button
                          className="Dashboard_page_OpenDoor_deactivate"
                          type="button"
                          disabled
                        >
                          {" "}
                          Open Cabin 2{" "}
                        </button>{" "}
                      </>
                    )}{" "}
                  </>
                )}
              </div>
            </div>
            <div className="dashboard-left-side-div-four-wrapper">
              <h5 className="dashboard-left-side-div-two-wrapper-h5">
                {" "}
                <span>
                  <SettingsOutlinedIcon className="text-[#b1b1b1]" />{" "}
                </span>
                Ottobot Actions
              </h5>
              <div className="dashboard-left-side-div-two-Map-Container-two">
                <RobotActions
                  robotId={robotId}
                  robotData={robotDetails[robotId]}
                />
              </div>
            </div>
          </div>
          <div className="p-0 col-md-9 dashboard-right-side-div-wrapper-Main">
            {(!isNaN(robotDetails[robotId]?.latitude) ||
              !isNaN(robotDetails[robotId]?.longitude) ||
              robotDetails[robotId]?.latitude !== undefined ||
              robotDetails[robotId]?.longitude !== undefined) && (
              <>
                {nogozones &&
                  geofencezone &&
                  fleetzones &&
                  robotId &&
                  robotDetails[robotId]?.latitude &&
                  robotDetails[robotId]?.longitude && (
                    <MapboxMain
                      robotpathcheck={state.robotpathcheck}
                      robotId={robotId}
                      geofencezone={geofencezone}
                      nogozones={nogozones}
                      fleetzones={fleetzones}
                      robotlatitude={
                        robotDetails[robotId].latitude &&
                        parseFloat(robotDetails[robotId]?.latitude)
                      }
                      robotlongitude={
                        robotDetails[robotId].longitude &&
                        parseFloat(robotDetails[robotId]?.longitude)
                      }
                    />
                  )}
                {map2D ? (
                  <button
                    className="Dashboard_page_2d_map_button"
                    onClick={handleminusbutton}
                  >
                    {" "}
                    <RemoveIcon />
                  </button>
                ) : (
                  <button
                    className="Dashboard_page_2d_map_button"
                    onClick={handleplusbutton}
                  >
                    {" "}
                    <AddIcon />
                  </button>
                )}
                {map2D ? <Maps fleetId={fleetId} /> : null}
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default React.memo(Dashboard);
