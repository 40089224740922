import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  cancelActionAPI,
  cancelOrderAPI,
  getorderstatus,
  getrobotStatus,
  robotactionapi,
  UpdateRobotAction,
} from "../API";
import ringer from "../components/alert.wav";
import CancelActionModel from "./CancelActionModel";
import RobotActionModal from "./RobotActionModal";
import io from "socket.io-client";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderStatus, getRobotDetail } from "../redux/actions";

function RobotActions(props) {
  var boxcolororange = false;
  let socket;
  // const serverUrl = 'http://localhost:3000';
  // const serverUrl ="https://goggoapi.ottonomy.io/"
  const audio = new Audio(ringer);
  // audio.loop = true;
  const [orderStatusvar, setorderStatusvar] = useState();
  const playbeepsound = () => {
    function play() {
      audio.play();
      audio.loop = true;
      audio.muted = false;
      // console.log(';sdsbfbndn audio')
    }
    play();
  };
  const pausebeepsound = () => {
    function pause() {
      audio.pause();
      audio.loop = false;
      audio.muted = true;
      audio.src = "";
      // console.log('ELement',document.getElementsByTagName('audio')[0].attributes('src'))
      // console.log('sdsbfbndn pause',audio)
    }
    pause();
  };

  const token = localStorage.getItem("token");
  const [robotData, setrobotData] = useState();
  // const robotId = localStorage.getItem('robotId')
  var robotId = props.robotId;
  const [commandlistdata, setcommandlistdata] = useState([]);
  const [orderId, setorderId] = useState(null);
  const [actionId, setactionId] = useState();
  const [finishbtn, setfinishbtn] = useState(true);
  var actionPriorityarray;
  var leastpriority = 1;
  const dispatch = useDispatch();

  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;

    const handleAudioLoad = () => {
      if (localStorage.getItem("audioPaused") === "false") {
        audio.play();
      }
    };

    if (audio) {
      audio.oncanplay = handleAudioLoad;
      return () => {
        audio.oncanplay = null;
      };
    }
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        localStorage.setItem("audioPaused", "true");
      } else {
        localStorage.setItem("audioPaused", "false");
      }
    }
  }, []);

  const getrobotactionStatus = (commandlistdata, orderId, actionId) => {
    switch (commandlistdata?.actionStatus) {
      case "0":
        return (
          <div className="RobotActions-button-wrapper">
            <RobotActionModal
              name="Start"
              actionId={actionId}
              orderId={orderId}
              number="1"
              robotId={props.robotId}
              commandlistdata={commandlistdata}
            />
          </div>
        );

      case null:
        return (
          <div className="RobotActions-button-wrapper">
            <audio
              preload="auto"
              ref={audioRef}
              loop
              autoPlay={true}
              id="playaudio"
            >
              <source
                src="/static/media/alert.aa9b8554cbeaf7968e81.wav"
                type="audio/wav"
              ></source>
            </audio>
            <RobotActionModal
              name="Start"
              actionId={actionId}
              orderId={orderId}
              number="1"
              robotId={props.robotId}
              commandlistdata={commandlistdata}
            />
          </div>
        );

      case "1":
        return (
          <div className="RobotActions-button-wrapper">
            <RobotActionModal
              name="Finish"
              actionId={actionId}
              orderId={orderId}
              number="3"
              robotId={props.robotId}
              commandlistdata={commandlistdata}
            />
          </div>
        );

      case "4":
        return (
          <div className="RobotActions-button-wrapper">
            <RobotActionModal
              name="Finish"
              actionId={actionId}
              orderId={orderId}
              number="3"
              robotId={props.robotId}
              commandlistdata={commandlistdata}
            />
          </div>
        );

      default:
        return null;
    }
  };

  const getouterborder = (commandlistdata) => {
    switch (commandlistdata[0]?.actionStatus) {
      case "4":
        return "RobotActions-content-wrapper-div orange_border";
      default:
        return "RobotActions-content-wrapper-div ";
    }
  };

  const { orderStatus } = useSelector((state) => state.orderStatusReducer);

  const updateState = useCallback(async () => {
    if (
      robotDetails[robotId]?.robotCommandList &&
      robotDetails[robotId]?.robotCommandList.length > 0
    )
      dispatch(
        getOrderStatus(robotDetails[robotId]?.robotCommandList[0].orderId)
      );
  }, [dispatch, robotDetails, robotId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 2000);

  return (
    <div
      className={`${
        robotDetails[robotId]?.robotCommandList &&
        robotDetails[robotId]?.robotCommandList.length > 0 &&
        getouterborder(robotDetails[robotId]?.robotCommandList)
      }`}
    >
      {/* {console.log('commandlistdatacommandlistdata',commandlistdata)} */}
      {/* {(() => {
        setTimeout(() => {
          setfinishbtn(true);
        }, 7000);
      })()} */}

      {robotDetails[robotId]?.robotCommandList &&
      robotDetails[robotId]?.robotCommandList.length > 0 ? (
        robotDetails[robotId]?.robotCommandList.map((item, index) => (
          // if(item.actionPriority === leastpriority){
          <div key={"robot__actions_" + index}>
            {item.actionPriority === 1 && (
              <div>
                <h3>
                  {" "}
                  Ottobot{" "}
                  {item.message === "MOVEHOME" ? (
                    <> Enroute to Home Location</>
                  ) : (
                    <>
                      <>{orderStatus}</>
                    </>
                  )}
                </h3>
                {item.actionStatus === null ||
                item.actionStatus === undefined ? null : (
                  <p>
                    {" "}
                    <span>{item.actionType}</span>
                  </p>
                )}
                <p>
                  {" "}
                  Order Id : <span>{item.orderId}</span>
                </p>
                <p>
                  {" "}
                  Action Status :{" "}
                  <span>
                    {item.actionStatus ? item.actionStatus : "No status"}
                  </span>
                </p>
                <p>
                  {" "}
                  Action Id : <span>{item.actionId}</span>
                </p>

                {/* <p> Location :    {item.latitude ||item.longitude ?<span>{item.latitude}, {item.longitude}</span>:item.locationName }  </p>  */}
                {item.actionType.includes("MOVE") ? (
                  ""
                ) : (
                  <p>
                    {" "}
                    Compartement:<span className="">{item.boxId}</span>
                  </p>
                )}
                {getrobotactionStatus(item, item.orderId, item.actionId)}
                {(() => {
                  if (item.actionStatus === "4") {
                    boxcolororange = true;
                  } else {
                    boxcolororange = false;
                  }
                })()}
                {(() => {
                  if (item?.actionStatus === "1") {
                    // pausebeepsound()
                  }
                })()}
              </div>
            )}
          </div>
          // }
        ))
      ) : (
        <p className="p-2 text-white"> No actions to show</p>
      )}
    </div>
  );
}

export default React.memo(RobotActions);
