import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SlPower } from "react-icons/sl";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { destroySession } from "../redux/actions";
import { useDispatch } from "react-redux";
// import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "#282F42",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function LogoutModel({ header, dashboard }) {
  const [logoutPopup, setLogoutPopup] = useState(false);

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setLogoutPopup(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlelogoutbtn = () => {
    localStorage.setItem("useremail", null);
    localStorage.setItem("password", null);
    localStorage.setItem("token", null);
    localStorage.setItem("fleetID", null);
    localStorage.setItem("Isloggedin", "LoginFalse");
    localStorage.setItem("mapid", null);
    localStorage.setItem("Distance", null);
    localStorage.setItem("robotId", null);
    localStorage.setItem("kmlurl", null);
    localStorage.setItem("firstName", null);
    localStorage.setItem("lastName", null);
    dispatch(destroySession());
    navigate("/");
    toast.success("Logged out successfully!");
  };
  return (
    <>
      {header ? (
        <button
          className="text-3xl cancel-order"
          onClick={() => setLogoutPopup(true)}
        >
          <SlPower className="text-[#00B7D4]" />
        </button>
      ) : (
        <button
          className="w-full text-left reset_diagonostics_button"
          onClick={() => setLogoutPopup(true)}
        >
          Logout
        </button>
      )}

      <Modal
        open={logoutPopup}
        // onHide={handleClose}
        backdrop="static"
        // keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Cancel-order-Modal-Mainwrapper">
            <p> Are you sure want to logout ?</p>
            <div className="Modal-button-Main-wrapper">
              <Button
                className="Modal-button-Main-btn yes-red-active"
                onClick={() => {
                  handleClose();
                  handlelogoutbtn();
                }}
              >
                {" "}
                Yes
              </Button>
              <Button className="Modal-button-Main-btn" onClick={handleClose}>
                {" "}
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default memo(LogoutModel);
