import { robotConstants } from "./constants";

const userlogindata = {};
export const UserloginData = (state = userlogindata, action) => {
  switch (action.type) {
    case "USER_LOGIN":
      return {
        ...state,
        userlogindata: action.payload,
      };
    default:
      return state;
  }
};
const fleetId = "";
export const fleetIdreducer = (state = fleetId, action) => {
  switch (action.type) {
    case "FETCH_FLEET_ID":
      return {
        ...state,
        fleetId: action.payload,
      };
    default:
      return state;
  }
};
const robotData = {};
export const robotdatareducer = (state = robotData, action) => {
  switch (action.type) {
    case "FETCH_ROBOT_DATA":
      return {
        ...state,
        robotData: action.payload,
      };
    default:
      return state;
  }
};
const robotmsgData = [];
export const robotMessageReducer = (state = robotmsgData, action) => {
  switch (action.type) {
    case "FETCH_ROBOTMSG_DATA":
      return {
        ...state,
        robotmsgData: action.payload,
      };
    default:
      return state;
  }
};
const robotID = [];
export const robotidReducer = (state = robotID, action) => {
  // console.log('RobotState', state,action.payload )
  switch (action.type) {
    case "GET_ROBOT_ID":
      return {
        ...state,
        robotID: action.payload,
      };
    default:
      return state;
  }
};

const robotlist = [];
export const robotlistReducer = (state = robotlist, action) => {
  // console.log('RobotState', state,action.payload )
  switch (action.type) {
    case "FETCH_ROBOT_LIST_DATA":
      return {
        ...state,
        robotlist: action.payload,
      };
    default:
      return state;
  }
};

const sshendpoint = "";
export const sshendpointReducer = (state = sshendpoint, action) => {
  switch (action.type) {
    case "FETCH_SSH_ENDPOINT":
      return {
        ...state,
        sshendpoint: action.payload,
      };
    default:
      return state;
  }
};

const latitude = "";
export const fleetlatitudeReducer = (state = latitude, action) => {
  switch (action.type) {
    case "FETCH_FLEET_LATITUDE":
      return {
        ...state,
        latitude: action.payload,
      };
    default:
      return state;
  }
};
const longitude = "";
export const fleetlongitudeReducer = (state = longitude, action) => {
  switch (action.type) {
    case "FETCH__FLEET_LONGITUDE":
      return {
        ...state,
        longitude: action.payload,
      };
    default:
      return state;
  }
};

export const robotDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case robotConstants.ROBOT_DETAILS_REQUEST:
    case robotConstants.ROBOT_STATUS_REQUEST:
    case robotConstants.ROBOT_ACTION_REQUEST:
      return {
        ...state,
      };

    case robotConstants.ROBOT_DETAILS_SUCCESS:
    case robotConstants.ROBOT_STATUS_SUCCESS:
    case robotConstants.ROBOT_ACTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case robotConstants.ROBOT_DETAILS_FAIL:
    case robotConstants.ROBOT_STATUS_FAIL:
    case robotConstants.ROBOT_ACTION_FAIL:
      return {
        ...state,
        // error: action.payload,
      };

    case robotConstants.DESTROY_SESSION:
      state = {};
      return state;

    case robotConstants.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const orderStatusReducer = (state = { orderStatus: "" }, action) => {
  switch (action.type) {
    case robotConstants.ORDER_DETAILS_REQUEST:
      return {
        ...state,
      };

    case robotConstants.ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderStatus: action.payload,
      };

    case robotConstants.ORDER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case robotConstants.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const robotDirectionsReducer = (
  state = { robotDirections: {} },
  action
) => {
  switch (action.type) {
    case robotConstants.ROBOT_DIRECTIONS_REQUEST:
      return {
        ...state,
      };

    case robotConstants.ROBOT_DIRECTIONS_SUCCESS:
      return {
        ...state,
        robotDirections: action.payload,
      };

    case robotConstants.ROBOT_DIRECTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case robotConstants.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const sshEndPointReducer = (state = { sshendpoint: "" }, action) => {
  switch (action.type) {
    case robotConstants.SSHENDPOINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case robotConstants.SSHENDPOINT_SUCCESS:
      return {
        ...state,
        sshendpoint: action.payload,
        loading: false,
      };

    case robotConstants.SSHENDPOINT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case robotConstants.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
