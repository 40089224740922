import { Link } from "react-router-dom";
import LogoutModel from "../subcomponents/LogoutModel";
import "../App.css";

function Header({ useremail, firstName, lastName, userRole }) {
  return (
    <div className="grid items-center py-3 text-white opacity-100">
      <div className="flex items-center justify-between px-3 sm:!px-8">
        <div className="nav-left">
          <div className="nav-logo">
            <Link to={"/"}>
              <img
                src="/assets/images/OttonomyLogo.png"
                className="HeaderMain-logo-image"
                alt="main__logo"
              />
            </Link>
          </div>
        </div>

        <div className="items-end flex text-sm sm:items-center sm:text-base flex-col sm:flex-row opacity-100 gap-[5px] ">
          <div className="flex flex-col items-start justify-center">
            {firstName &&
              firstName !== "undefined" &&
              firstName !== "null" &&
              lastName &&
              lastName !== "undefined" &&
              lastName !== "null" &&
              useremail &&
              useremail !== "undefined" &&
              useremail !== "null" && (
                <span className="capitalize user-email">
                  {firstName && firstName + " " + lastName}
                  {!firstName && useremail}
                </span>
              )}
            {userRole && userRole !== "undefined" && (
              <span className="m-0 text-xs text-white capitalize opacity-70">
                {userRole}
              </span>
            )}
          </div>
          <LogoutModel header={true} />
        </div>
      </div>
    </div>
  );
}

export default Header;
